<template>
	<div class="md-layout">
		<div
			id="principal"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<FilterSelector
				@searchData="showLogs()"
				:disableSearch="!this.selectPlaylist"
				:disablePrint="this.tableLogs.length === 0"
				:filterForReport="true"
				:buttonsToDisplay="[
					buttonEnum.YESTERDAY,
					buttonEnum.SEVEN_DAYS,
					buttonEnum.MONTH_TO_DATE,
					buttonEnum.CUSTOM,
				]"
			>
				<template #filters>
					<div class="md-layout">
						<h3
							class="md-layout-item md-size-100"
							style="
								font-size: 1rem;
								text-align: left;
								margin: 0;
								padding: 0;
								margin-top: 25px;
								font-weight: 100;
							"
						>
							Selecione a playlist
						</h3>
						<el-select v-model="selectPlaylist" placeholder="Playlist...">
							<el-option
								v-for="(item, index) in playlists"
								:key="index"
								:label="item.name"
								:value="item.id"
							>
								{{ item.name }}
							</el-option>
						</el-select>
					</div>
				</template>
				<template #other-options>
					<md-button
						@click="printReport()"
						:disabled="tableLogs.length === 0"
						class="md-info"
					>
						Imprimir
					</md-button>
				</template>
			</FilterSelector>

			<div
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<md-card v-show="logsStatus == true">
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<div class="card-icon">
							<i class="material-icons">assessment</i>
						</div>
					</md-card-header>

					<md-card-content>
						<div
							class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
						>
							<md-table v-model="tableLogs" table-header-color="green">
								<md-table-row slot="md-table-row" slot-scope="{ item }">
									<md-table-cell md-label="Horário">{{
										item.time
									}}</md-table-cell>
									<md-table-cell md-label="Acessos à playlist">{{
										item.opened
									}}</md-table-cell>
									<md-table-cell md-label="Músicas executadas">{{
										item.musicPlayed
									}}</md-table-cell>
									<md-table-cell md-label="Execuções da playlist">{{
										item.played
									}}</md-table-cell>
								</md-table-row>
							</md-table>
						</div>
					</md-card-content>
				</md-card>

				<md-card style="text-align: center" v-show="logsStatus == false">
					<md-card-content>
						<label for="">Sem informações pra serem exibidas.</label>
					</md-card-content>
				</md-card>
			</div>
		</div>

		<div id="impressao" class="md-layout">
			<div>
				<img src="../../../../public/img/logo-mobradio-black.png" alt="" />
				<h1>Relatório de Acessos às playlists</h1>
			</div>
			<div class="titleRight">
				<b class="nameRadio">{{
					nameRadioReport ? nameRadioReport : "MobRadio"
				}}</b>
				&nbsp;
				<img class="logoRadio" :src="logo" alt="" />
			</div>
			<div>
				<span>Período: {{ periodStart }} até: {{ periodEnd }} </span>
			</div>
			<div class="contentBody impressao">
				<div class="divTable">
					<span style="padding-left: 5px; font-style: italic"
						>Playlist: {{ viewPlaylistSelect }}</span
					>
					<div
						class="md-content md-table md-theme-default"
						table-header-color="blue"
					>
						<div
							class="md-content md-table-content md-scrollbar md-theme-default"
						>
							<table>
								<thead>
									<tr>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Horário</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">
													Acessos à playlist
												</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">
													Músicas executadas
												</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">
													Execuções da playlist
												</div>
											</div>
										</th>
									</tr>
								</thead>
								<tfoot class="report-footer">
									<tr>
										<td colspan="6">
											Fonte de dados: MobRadio Analytics &nbsp;
											<img
												class="footerIcon"
												src="../../../../public/img/favicon.png"
											/>
										</td>
									</tr>
								</tfoot>
								<tbody>
									<tr
										class="md-table-row"
										v-for="(item, index) in tableLogs"
										:key="index"
									>
										<td class="md-table-cell">
											<div class="md-table-cell-container">{{ item.time }}</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.opened | formatNumber }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.musicPlayed | formatNumber }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.played | formatNumber }}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import Rodape from "./Rodape.vue";
import FilterSelector from "../Components/FilterSelector/FilterSelector.vue";
import filters from "../Components/FilterSelector/filters";
import Spinner from "../../../components/Icons/Spinner";
import models from "../../../routes/models.js";
import { ChartCard } from "@/components";
import jwt_decode from "jwt-decode";
import { DatePicker } from "element-ui";
import buttonEnum from "../Components/FilterSelector/buttonEnum";

const numeral = require("numeral");

numeral.locale("pt-br");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

export default {
	components: {
		ChartCard,
		[DatePicker.name]: DatePicker,
		Rodape,
		FilterSelector,
		Spinner,
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			radio: jwt_decode(localStorage.getItem("APP_01")).radio,
			nameRadioReport: jwt_decode(localStorage.getItem("APP_01")).radio.name,
			logo: jwt_decode(localStorage.getItem("APP_01")).radio.logo,
			periodStart: null,
			periodEnd: null,
			btnImprimir: true,
			disabledButton: true,
			logsStatus: null,
			tableLogs: [],
			time1: null,
			time2: null,
			playlists: [],
			selectPlaylist: null,
			viewPlaylistSelect: "",
		};
	},
	methods: {
		showLogs() {
			Promise.resolve(this.getLogsAccessPerRadio())
				.then((result) => {
					if (result.data.length == 0) {
						this.logsStatus = false;
						this.btnImprimir = true;
					} else {
						let opened1 = 0,
							played1 = 0,
							musicPlayed1 = 0,
							opened2 = 0,
							played2 = 0,
							musicPlayed2 = 0,
							opened3 = 0,
							played3 = 0,
							musicPlayed3 = 0,
							opened4 = 0,
							played4 = 0,
							musicPlayed4 = 0,
							opened5 = 0,
							played5 = 0,
							musicPlayed5 = 0,
							opened6 = 0,
							played6 = 0,
							musicPlayed6 = 0,
							opened7 = 0,
							played7 = 0,
							musicPlayed7 = 0;
						for (let data of result.data) {
							const timeString = new Date(data.date).toLocaleTimeString(
								"pt-br"
							);
							const str = parseInt(timeString.substring(0, 2));
							if (str >= 0 && str <= 6) {
								opened1 += parseInt(data.opened);
								played1 += parseInt(data.played);
								musicPlayed1 = parseInt(data.music_played);
							}
							if (str >= 6 && str <= 9) {
								opened2 += parseInt(data.opened);
								played2 += parseInt(data.played);
								musicPlayed2 += parseInt(data.music_played);
							}
							if (str >= 9 && str <= 12) {
								opened3 += parseInt(data.opened);
								played3 += parseInt(data.played);
								musicPlayed3 += parseInt(data.music_played);
							}
							if (str >= 12 && str < 14) {
								opened4 += parseInt(data.opened);
								played4 += parseInt(data.played);
								musicPlayed4 += parseInt(data.music_played);
							}
							if (str >= 14 && str <= 17) {
								opened5 += parseInt(data.opened);
								played5 += parseInt(data.played);
								musicPlayed5 += parseInt(data.music_played);
							}
							if (str >= 17 && str <= 20) {
								opened6 += parseInt(data.opened);
								played6 += parseInt(data.played);
								musicPlayed6 += parseInt(data.music_played);
							}
							if (str >= 20 && str <= 24) {
								opened7 += parseInt(data.opened);
								played7 += parseInt(data.played);
								musicPlayed7 += parseInt(data.music_played);
							}

							this.tableLogs = [];
							this.tableLogs.push(
								{
									time: "00:00 - 05:59",
									opened: opened1,
									played: played1,
									musicPlayed: musicPlayed1,
								},
								{
									time: "06:00 - 08:59",
									opened: opened2,
									played: played2,
									musicPlayed: musicPlayed2,
								},
								{
									time: "09:00 - 11:59",
									opened: opened3,
									played: played3,
									musicPlayed: musicPlayed3,
								},
								{
									time: "12:00 - 13:59",
									opened: opened4,
									played: played4,
									musicPlayed: musicPlayed4,
								},
								{
									time: "14:00 - 16:59",
									opened: opened5,
									played: played5,
									musicPlayed: musicPlayed5,
								},
								{
									time: "17:00 - 19:59",
									opened: opened6,
									played: played6,
									musicPlayed: musicPlayed6,
								},
								{
									time: "20:00 - 23:59",
									opened: opened7,
									played: played7,
									musicPlayed: musicPlayed7,
								}
							);
							this.periodStart = new Date(filters.startDate).toLocaleDateString(
								"pt-BR"
							);
							this.periodEnd = new Date(filters.endDate).toLocaleDateString(
								"pt-BR"
							);
							this.btnImprimir = false;
							this.logsStatus = true;
							this.viewPlaylistSelect =
								this.$refs.selectPlaylist.$refs.input.value;
						}
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		async getLogsAccessPerRadio() {
			let dateStart = filters.startDate;
			let dateEnd = filters.endDate;
			dateStart =
				dateStart.getFullYear() +
				"-" +
				(dateStart.getMonth() + 1) +
				"-" +
				dateStart.getDate() +
				" 00:00:00";
			dateEnd =
				dateEnd.getFullYear() +
				"-" +
				(dateEnd.getMonth() + 1) +
				"-" +
				dateEnd.getDate() +
				" 23:59:59";
			return await models().get(
				`music/getAccessPerRadio?appKey=${this.radio.key}&playlistId=${this.selectPlaylist}&dateStart=${dateStart}&dateEnd=${dateEnd}`
			);
		},
		getPlaylistsPerRadio() {
			this.playlists = [];
			models()
				.get(`music/getAllPlaylistsPerRadio?appKey=${this.radio.key}`)
				.then((playlists) => {
					for (let obj of playlists.data) {
						this.playlists.push({
							id: obj.id,
							name: obj.name,
						});
					}
				});
		},
		printReport() {
			window.print();
		},
	},
	mounted() {
		this.getPlaylistsPerRadio();
	},
	watch: {
		time1() {
			if (
				this.time1 != null &&
				this.time2 !== null &&
				this.selectPlaylist !== null
			) {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
		},
		time2() {
			if (
				this.time1 != null &&
				this.time2 !== null &&
				this.selectPlaylist !== null
			) {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
		},
		selectPlaylist() {
			if (
				this.time1 != null &&
				this.time2 !== null &&
				this.selectPlaylist !== null
			) {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
		},
	},
};
</script>
<style>
#impressao {
	display: none;
}

@page {
	size: auto;
	margin: 10mm 10mm 10mm 10mm;
}

@media print {
	#principal {
		display: none;
	}

	#impressao {
		display: block;
		margin-top: -30px;
	}

	tr {
		page-break-inside: avoid;
	}

	table.report-container {
		page-break-after: always !important;
	}

	thead.report-header {
		display: table-header-group !important;
	}

	tfoot.report-footer {
		display: table-footer-group !important;
		text-align: center;
		font-size: 12pt;
	}

	body {
		font-size: 8pt;
	}

	img {
		width: 160px;
		height: 160px;
	}

	h1 {
		font-size: 18pt;
	}

	span {
		font-size: 12pt;
	}

	footer {
		text-align: center;
		font-size: 12pt;
	}

	.order-by {
		font-size: 10pt;
		font-style: italic;
		padding-left: 5px;
	}

	.md-toolbar-section-start {
		display: none;
	}

	.titleRight {
		float: right;
		margin-top: -80px;
	}

	.nameRadio {
		font-size: 18pt;
	}

	.logoRadio {
		width: 48px;
		height: 48px;
		border-radius: 25px;
		margin-top: -10px;
	}

	.contentBody {
		margin-top: 50px;
	}

	.divTable {
		border: 1px solid #d2d2d2;
		padding: 10px;
		margin-bottom: 20px;
	}

	.footerIcon {
		width: 16px;
		height: 16px;
		margin-top: -5px;
	}
}
</style>
