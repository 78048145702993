var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      {
        staticClass:
          "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        attrs: { id: "principal" },
      },
      [
        _c("FilterSelector", {
          attrs: {
            disableSearch: !this.selectPlaylist,
            disablePrint: this.tableLogs.length === 0,
            filterForReport: true,
            buttonsToDisplay: [
              _vm.buttonEnum.YESTERDAY,
              _vm.buttonEnum.SEVEN_DAYS,
              _vm.buttonEnum.MONTH_TO_DATE,
              _vm.buttonEnum.CUSTOM,
            ],
          },
          on: {
            searchData: function ($event) {
              return _vm.showLogs()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "filters",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "md-layout" },
                    [
                      _c(
                        "h3",
                        {
                          staticClass: "md-layout-item md-size-100",
                          staticStyle: {
                            "font-size": "1rem",
                            "text-align": "left",
                            margin: "0",
                            padding: "0",
                            "margin-top": "25px",
                            "font-weight": "100",
                          },
                        },
                        [_vm._v(" Selecione a playlist ")]
                      ),
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Playlist..." },
                          model: {
                            value: _vm.selectPlaylist,
                            callback: function ($$v) {
                              _vm.selectPlaylist = $$v
                            },
                            expression: "selectPlaylist",
                          },
                        },
                        _vm._l(_vm.playlists, function (item, index) {
                          return _c(
                            "el-option",
                            {
                              key: index,
                              attrs: { label: item.name, value: item.id },
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "other-options",
              fn: function () {
                return [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-info",
                      attrs: { disabled: _vm.tableLogs.length === 0 },
                      on: {
                        click: function ($event) {
                          return _vm.printReport()
                        },
                      },
                    },
                    [_vm._v(" Imprimir ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
          },
          [
            _c(
              "md-card",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.logsStatus == true,
                    expression: "logsStatus == true",
                  },
                ],
              },
              [
                _c(
                  "md-card-header",
                  { staticClass: "md-card-header-icon md-card-header-blue" },
                  [
                    _c("div", { staticClass: "card-icon" }, [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("assessment"),
                      ]),
                    ]),
                  ]
                ),
                _c("md-card-content", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                    },
                    [
                      _c("md-table", {
                        attrs: { "table-header-color": "green" },
                        scopedSlots: _vm._u([
                          {
                            key: "md-table-row",
                            fn: function ({ item }) {
                              return _c(
                                "md-table-row",
                                {},
                                [
                                  _c(
                                    "md-table-cell",
                                    { attrs: { "md-label": "Horário" } },
                                    [_vm._v(_vm._s(item.time))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": "Acessos à playlist",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.opened))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": "Músicas executadas",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.musicPlayed))]
                                  ),
                                  _c(
                                    "md-table-cell",
                                    {
                                      attrs: {
                                        "md-label": "Execuções da playlist",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.played))]
                                  ),
                                ],
                                1
                              )
                            },
                          },
                        ]),
                        model: {
                          value: _vm.tableLogs,
                          callback: function ($$v) {
                            _vm.tableLogs = $$v
                          },
                          expression: "tableLogs",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "md-card",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.logsStatus == false,
                    expression: "logsStatus == false",
                  },
                ],
                staticStyle: { "text-align": "center" },
              },
              [
                _c("md-card-content", [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Sem informações pra serem exibidas."),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "md-layout", attrs: { id: "impressao" } }, [
      _vm._m(0),
      _c("div", { staticClass: "titleRight" }, [
        _c("b", { staticClass: "nameRadio" }, [
          _vm._v(
            _vm._s(_vm.nameRadioReport ? _vm.nameRadioReport : "MobRadio")
          ),
        ]),
        _vm._v("   "),
        _c("img", {
          staticClass: "logoRadio",
          attrs: { src: _vm.logo, alt: "" },
        }),
      ]),
      _c("div", [
        _c("span", [
          _vm._v(
            "Período: " +
              _vm._s(_vm.periodStart) +
              " até: " +
              _vm._s(_vm.periodEnd) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "contentBody impressao" }, [
        _c("div", { staticClass: "divTable" }, [
          _c(
            "span",
            { staticStyle: { "padding-left": "5px", "font-style": "italic" } },
            [_vm._v("Playlist: " + _vm._s(_vm.viewPlaylistSelect))]
          ),
          _c(
            "div",
            {
              staticClass: "md-content md-table md-theme-default",
              attrs: { "table-header-color": "blue" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "md-content md-table-content md-scrollbar md-theme-default",
                },
                [
                  _c("table", [
                    _vm._m(1),
                    _vm._m(2),
                    _c(
                      "tbody",
                      _vm._l(_vm.tableLogs, function (item, index) {
                        return _c(
                          "tr",
                          { key: index, staticClass: "md-table-row" },
                          [
                            _c("td", { staticClass: "md-table-cell" }, [
                              _c(
                                "div",
                                { staticClass: "md-table-cell-container" },
                                [_vm._v(_vm._s(item.time))]
                              ),
                            ]),
                            _c("td", { staticClass: "md-table-cell" }, [
                              _c(
                                "div",
                                { staticClass: "md-table-cell-container" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(item.opened)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "md-table-cell" }, [
                              _c(
                                "div",
                                { staticClass: "md-table-cell-container" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(item.musicPlayed)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "md-table-cell" }, [
                              _c(
                                "div",
                                { staticClass: "md-table-cell-container" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(item.played)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: {
          src: require("../../../../public/img/logo-mobradio-black.png"),
          alt: "",
        },
      }),
      _c("h1", [_vm._v("Relatório de Acessos às playlists")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Horário"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v(" Acessos à playlist "),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v(" Músicas executadas "),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head" }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v(" Execuções da playlist "),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tfoot", { staticClass: "report-footer" }, [
      _c("tr", [
        _c("td", { attrs: { colspan: "6" } }, [
          _vm._v(" Fonte de dados: MobRadio Analytics   "),
          _c("img", {
            staticClass: "footerIcon",
            attrs: { src: require("../../../../public/img/favicon.png") },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }